<template>
    <div>
        <v-col class="my-0 py-0 px-0 px-md-8 pb-15">
            <v-col class="text-start mt-5 mb-3">
                <v-alert text dense color="#8061c2" border="left" class="pl-5">
                    <h1 class="title mt-1">Plataformas</h1>
                    <p class="desc mt-0 mb-1">Listado de plataformas.</p>
                </v-alert>
            </v-col>
            <v-row class="mx-3">
                <v-col cols="12" md="4" class="px-0">
                    <v-text-field v-model="search" color="#8061c2" label="Buscar" append-icon="mdi-magnify" single-line
                        hide-details outlined class="mb-2"></v-text-field>
                </v-col>
                <v-col cols="12" md="4" class="px-md-2 px-0">
                    <v-select v-model="filterType" label="Tipo de Plataforma" :items="filterOptions" outlined hide-details></v-select>
                </v-col>
                <v-col cols="12" md="4" class="px-0">
                    <v-select v-model="sortDesc" :items="sortOptions" label="Ordenar" outlined hide-details></v-select>
                </v-col>
            </v-row>
            <v-data-table 
                :headers="headers" 
                :items="filteredPlatforms" 
                :search="search"
                :footer-props="{ itemsPerPageOptions: [50, 100, 200, 300, -1], showFirstLastPage: true}"
                class="elevation-1 mx-3 px-2 py-4">
                <template v-slot:item.count="{ item }">
                    {{ item.count.count }}
                </template>

                <template v-slot:item.platform="{ item }">
                    {{ item.platform }}
                </template>


                <template v-slot:item.actions="{ item }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn text v-bind="attrs" v-on="on" :loading="item.loading"  @click="toggleAdultContent(item)">
                                <v-icon color="#d33" v-if="item.count.is_adult">mdi-lock</v-icon>
                                <v-icon v-else color="#28b761 ">mdi-lock-open</v-icon>
                            </v-btn>
                        </template>
                        <span v-if="item.count.is_adult">Desmarcar como adultos</span>
                        <span v-else>Marcar como adultos</span>
                    </v-tooltip>
                </template>


            </v-data-table>
        </v-col>
    </div>
</template>

<script>
import axios from '@/axios.js';

export default {
    data() {
        return {
            headers: [
                { text: "Plataforma", value: "platform", sortable: true },
                { text: "Cantidad", value: "count", sortable: true },
                { text: "Acciones", value: "actions", sortable: false }
            ],
            loading: false,
            platforms: [],
            search: '',
            filterType: null,
            sortDesc: true,
            sortOptions: [
                { text: "Mayor a menor", value: true },
                { text: "Menor a mayor", value: false }
            ],
            filterOptions: [
                { text: "Todas", value: null },
                { text: "Propias", value: true },
                { text: "No propias", value: false }
            ],
        };
    },
    computed: {
        filteredPlatforms() {
            let filtered = this.platforms.filter(platform =>
                platform.platform.toLowerCase().includes(this.search.toLowerCase())
            );

            if (this.filterType !== null) {
                filtered = filtered.filter(platform => platform.count.is_own === this.filterType);
            }
            filtered.sort((a, b) => {
                return this.sortDesc ? b.count.count - a.count.count : a.count.count - b.count.count;
            });

            return filtered;
        }
    },
    methods: {
        async fetchPlatforms() {
            this.loading = true;
            try {
                const response = await axios.get('/admin/platforms-count');
                this.platforms = Object.entries(response.data).map(([platform, count]) => ({
                    platform,
                    count,
                    loading: false,
                }));
            } catch (error) {
                console.error("Error fetching platform counts:", error);
            } finally {
                this.loading = false;
            }
        },

        async toggleAdultContent(platform) {
            const action = platform.count.is_adult ? 'quitar' : 'marcar';
            const actionToast = platform.count.is_adult ? 'quitada' : 'marcada';
            const confirmationText = `¿Estás seguro de que quieres ${action} esta plataforma como contenido para adultos?`;
            this.$swal({
                title: `${action.charAt(0).toUpperCase() + action.slice(1)} como contenido para adultos`,
                text: confirmationText,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Sí, confirmar',
                cancelButtonText: 'Cancelar',
                confirmButtonColor: '#8e84c0',
                cancelButtonColor: '#d33',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    platform.loading = true;
                    try {
                        const response = await axios.post('/admin/mark-adult-platform', {
                            name_platform: platform.platform,
                            is_adult: !platform.count.is_adult
                        });
                        this.fetchPlatforms();
                        if (response.data.success) {
                            this.$vs.notify({
                                title: '¡Hecho!',
                                text: `La plataforma ha sido ${actionToast} como contenido para adultos.`,
                                color: 'success',
                                icon: 'check_box',
                                position: 'bottom-center',
                                time: 5000,
                            });
                        }
                    } catch (error) {
                        console.error(error);
                        this.$toast.error('Hubo un problema al completar la operación. Por favor, intenta de nuevo.');
                    } finally {
                        platform.loading = false;
                    }
                }
            });
        }
    },
    mounted() {
        this.fetchPlatforms();
    }
};
</script>